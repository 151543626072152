<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='栏目名称' prop='name'>
          <a-input v-model='form.name' />
        </a-form-model-item>
        <a-form-model-item label='所属板块' prop='prototype'>
          <a-radio-group v-model='form.prototype'>
            <a-radio value='introduce'>企业文化</a-radio>
            <a-radio value='business'>商学院</a-radio>
            <a-radio value='case'>客户见证</a-radio>
            <a-radio value='taisui'>太岁</a-radio>
            <a-radio value='class'>养生课堂</a-radio>
            <a-radio value='question'>常见问题</a-radio>
            <a-radio value='other'>其他内容</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='栏目状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio value='online'>启用</a-radio>
            <a-radio value='offline'>停用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="form.prototype == 'taisui' || form.prototype == 'class'" label='栏目封面' prop='cover'>
          <a-upload
            list-type='picture-card'
            class='avatar-uploader'
            :show-upload-list='false'
            :customRequest='handleUpload'>
            <img v-if='form.cover' :src='form.cover' alt='cover' width='375px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='排序优先级' prop='priority' help='数字越大优先级又高'>
          <a-input-number v-model='form.priority' />
        </a-form-model-item>
        <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
          <a-button @click='handleReset'>
            重置
          </a-button>
          <a-divider type='vertical' />
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  getCampusSubject,
  putCampusSubject
} from '@/api/content'
import client from '@/config/oss.config.js'


export default {
  name: 'ContentCategoryEdit',
  data() {
    return {
      labelCol: { lg: { span: 6 }, sm: { span: 6 } },
      wrapperCol: { lg: { span: 16 }, sm: { span: 16 } },
      loading: false,
      form: {
        id: '',
        name: '',
        status: '',
        cover: '',
        deleted: 0,
        priority: 0,
        prototype: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写栏目名称', trigger: 'change' },
          { min: 4, max: 6, message: '栏目名称字符数限定4～6个', trigger: 'change' }
        ],
        status: [
          { required: true, message: '请选择栏目状态', trigger: 'change' }
        ],
        prototype: [
          { required: true, message: '请选择所属板块', trigger: 'change' }
        ],
        cover: [{ required: true, message: '请上传内容封面', trigger: 'change' }],
        priority: [
          { required: true, message: '请填写排序优先级', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.handleInitial()
    this.$form.createForm(this)
  },
  activated() {
    this.handleInitial()
    this.$form.createForm(this)
    // debugger;
  },
  methods: {
    async handleUpload(event) {
      this.loading = true;
      let result = await client.put('/article/case/' + event.file.uid, event.file)
      this.form.cover = result.url
      this.loading = false;
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getCampusSubject(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
        })
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          putCampusSubject(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
